import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionManagerService } from '../services/session-manager.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PublicRouteGuard implements CanActivate {

  constructor(public sessionManager: SessionManagerService, public router: Router) { }

  canActivate(): boolean {
    if (this.sessionManager.loggedIn) {
      console.log(this.sessionManager.patientData.patient_id)
      if(this.sessionManager.patientData.patient_id) {
        this.router.navigate(['/loginPatient']);
      } else {
        this.router.navigate(['/dashboard']);
      }
      return false;
    }
    return true;
  }

}
