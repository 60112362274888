import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Ticket} from "../../../core/models/ticket.model";
import {AccountModel} from "../../../core/models/account.model";
import {PatientModel} from "../../../core/models/patient.model";
import {ProcedureModel} from "../../../core/models/procedure.model";
import {StatusStage} from "../../../core/models/status-stage.model";
import {ProfessionalModel} from "../../../core/models/professional.model";
import {HealthInsurancePatientModel} from "../../../core/models/health-insurance-patient.model";
import {
  FinancialReportComponent
} from "../../../features/dashboard/financial/financial-report/financial-report.component";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionManagerService} from "../../../core/services/session-manager.service";
import {ToastrService} from "ngx-toastr";
import {AccountsService} from "../../../core/services/accounts.service";
import {FinancialService} from "../../../core/services/financial.service";
import {documentsStringFormatter} from "../../utils/string-formatters";
import * as moment from "moment/moment";
import {PermissionConst} from "../../../core/models/permission-const.model";
import {HttpErrorResponse} from "@angular/common/http";
import {TicketPostModel} from "../../../core/models/ticket-post.model";
import {AttachmentModalComponent} from "../attachment-modal/attachment-modal.component";
import {AttachmentPostModel} from "../../../core/models/attachment-post.model";
import {MailingModalComponent} from "../mailing-modal/mailing-modal.component";
import {ProtocolModalComponent} from "../protocol-modal/protocol-modal.component";
import {FinancialTask} from "../../../core/models/financial-task.model";
import {TaskRelation} from "../../../core/models/task-relation.model";

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss']
})
export class TicketComponent implements OnInit {
  taskList: FinancialTask[] = [];

  detailHeader = [
    {title: 'PROCEDIMENTO', width: 'col-3', alt: 'PROCEDIMENTO'},
    {title: 'STATUS', width: 'col-2', alt: 'STATUS'},
    {title: 'PROTOCOLO', width: 'col-1', alt: 'PROTOCOLO'},
    {title: 'DATA DO PEDIDO REEMBOLSO', width: 'col-1', alt: 'DATA DO PEDIDO REEMBOLSO'},
    {title: 'VALOR NOTA FISCAL', width: 'col-1', alt: 'VALOR NOTA FISCAL'},
    {title: 'NÚMERO NOTA FISCAL', width: 'col-1', alt: 'NÚMERO NOTA FISCAL'},
    {title: 'VALOR REEM', width: 'col-1', alt: 'VALOR REEMBOLSO'},
    {title: 'DATA REEMBOLSO', width: 'col-1', alt: 'DATA REEMBOLSO'},
    {title: 'VALOR COPART', width: 'col-1', alt: 'VALOR COPARTICIPAÇÃO'},
    {title: 'DATA RECEBIMENTO', width: 'col-1', alt: 'DATA RECEBIMENTO'},
    {title: 'VALOR RECEB', width: 'col-1', alt: 'VALOR RECEBIDO'},
    {title: 'ANEXOS', width: 'col', alt: ''},
  ];

  historicHeader = [
    {title: 'DATA E HORA', width: 'col-1'},
    {title: 'USUÁRIO', width: 'col-1'},
    {title: 'ANEXOS', width: 'col-1'},
    {title: 'OBSERVAÇÕES', width: 'col-9'},
  ];

  formGroup = this.fb.group({
    date_time: ['', Validators.required],
    user_id: ['', Validators.required],
    patient_id: ['', Validators.required],
    health_insurance_plan_id: ['', Validators.required],
    attachment: this.fb.array([]),
    ticket_procedure: this.fb.array([]),
    professional_id: ['', Validators.required],
    login: [''],
    password: [''],
    dependent: [''],
    log: this.fb.group({
      notes: ['', Validators.required],
      protocol: [''],
      attachment: this.fb.array([])
    }),
    request_credentials: [true],
    synchronization: [false],
    contract_confirmation: [false],
    is_profile_validated: [false],
    current_task: this.fb.group({
      id: [null],
      task_id: [null],
      ticket_id: [null],
      task_completed: [false],
      due_date: [null]
    }),
  });

  subTitle = 'Novo Ticket';
  protocolName = 'Novo protocolo';
  id!: number;

  loadingPage: boolean = true;
  isRegister: boolean = true;
  submitting: boolean = false;

  showLoginAndPassword = false;

  queryParams: any;

  fetchedTicket: Ticket = {};
  userList: AccountModel[] = [];
  patientList: PatientModel[] = [];
  proceduresList: ProcedureModel[] = [];
  statusList: StatusStage[] = [];
  professionalList: ProfessionalModel[] = []
  healthInsuranceList: HealthInsurancePatientModel[] = [];

  financialReport?: FinancialReportComponent;

  completedTasks: TaskRelation[] = []

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService,
              private accountsService: AccountsService, private financialService: FinancialService, private modalService: NgbModal, private router: Router, private activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    if (this.id) {
      this.isRegister = false;
      this.subTitle = "Carregando...";
    }

    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = {
        dateStart: params['dateStart'],
        dateEnd: params['dateEnd']
      }
    });

    this.initializeForm();
  }

  initializeForm() {
    this.fetchFinancialTasks();
    this.fetchPatients();
    this.fetchProfessionals();
    this.fetchUsers();
    this.fetchProcedures();
    this.fetchStatus();
    this.addProcedure();
    if (this.id) this.fetchTicketExistingData();
    else this.loadingPage = false;
  }

  get currentTaskFormGroup(): FormGroup {
    return this.formGroup.get('current_task') as FormGroup;
  }

  get selectedPatient() {
    let patient;
    let patientId = this.formGroup.get('patient_id')?.value;
    patient = this.patientList.find(patient => patient.id == patientId);
    return patient;
  }

  get selectedPatientDocumentNumber() {
    if (this.selectedPatient!.document_number) {
      return documentsStringFormatter(this.selectedPatient!.document_number!);
    }
    return '';
  }

  get selectedPatientAge() {
    if (this.selectedPatient) {
      if (this.selectedPatient.birthdate) {
        let birthdate = moment(this.selectedPatient.birthdate);
        let today = moment();

        let days = today.date() - birthdate.date();
        let months = (today.month() + 1) - (birthdate.month() + 1);
        let years = today.year() - birthdate.year();

        if (today.date() < birthdate.date()) {
          days += 30;
          months -= 1;
        }

        if (today.month() + 1 < birthdate.month() + 1) {
          months += 12;
          years -= 1;
        }

        return `(${years} anos ${months} meses e ${days} dias)`;
      }
      return '';
    }
    return '';
  }

  get canAddProcedure() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_procedure_to_an_existent_ticket);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.add_ticket);
    }
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_ticket);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_ticket);
    }
  }

  verifyIfItIsSelected(name: string) {
    if (name == 'credentials') {
      this.formGroup.get('synchronization')?.setValue(false);
    } else {
      this.formGroup.get('request_credentials')?.setValue(false);
    }
  }

  fetchPatients() {
    this.financialService.patientGetAll().subscribe(response => {
      this.patientList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchProfessionals() {
    this.financialService.professionalGetAll().subscribe(response => {
      this.professionalList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchUsers() {
    this.accountsService.accountGetAll('').subscribe(response => {
      this.userList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchProcedures() {
    this.financialService.procedureGetAll().subscribe(response => {
      this.proceduresList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchStatus() {
    this.financialService.financialStatusAll(true).subscribe(response => {
      this.statusList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  dateHandle(date: string, isDateTime: boolean) {
    if (date == undefined) return '';
    if (isDateTime) return moment(date).format('DD/MM/YYYY HH:mm');
    else return moment(date).format('DD/MM/YYYY');
  }

  fetchTicketExistingData() {
    this.financialService.financialTicketGetById(this.id).subscribe((response) => {
      this.fetchedTicket = response;
      this.subTitle = this.fetchedTicket.patient?.name ?? '';
      this.proceduresForm.clear();
      response.ticket_procedure_list?.forEach((procedure, index) => {
        this.addProcedure();
        if (procedure.medical_orders) procedure.medical_orders.forEach(value => this.createMedicalOrder(index));
        if (procedure.medical_reports) procedure.medical_reports.forEach(value => this.createMedicalReport(index));
        this.getProcedureFormGroup(index).patchValue(procedure);
        this.getProcedureFormGroup(index).get('status_id')?.setValue(procedure.status?.status_id);
        if (procedure.refund_request_date) this.getProcedureFormGroup(index).get('refund_request_date')?.setValue(moment(procedure.refund_request_date).format('YYYY-MM-DD'));
        if (procedure.refund_date) this.getProcedureFormGroup(index).get('refund_date')?.setValue(moment(procedure.refund_date).format('YYYY-MM-DD'));
        if (procedure.receipt_date) this.getProcedureFormGroup(index).get('receipt_date')?.setValue(moment(procedure.receipt_date).format('YYYY-MM-DD'));
      });
      this.getHealthInsurance(this.fetchedTicket.patient);
      this.formGroup.patchValue(this.fetchedTicket as Partial<{}>);
      this.formGroup.get('date_time')!.setValue(moment(this.fetchedTicket.date_time).format('YYYY-MM-DD'));
      this.formGroup.get('is_profile_validated')?.disable();
      this.formGroup.get('contract_confirmation')?.disable();
      this.currentTaskFormGroup.get('ticket_id')?.patchValue(this.fetchedTicket.id);
      this.loadingPage = false;
      this.completedTasks = this.fetchedTicket.tasks?.filter(el => el.task_completed) || [];
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  getProtocolName(index: number) {
    let procedureProtocolForm = this.getProcedureFormGroup(index).get('protocol') as FormArray;
    if (procedureProtocolForm.length > 0) {
      let procedures = procedureProtocolForm.value;
      procedures.sort((a: any, b: any) => {
        if (a.date_time > b.date_time) {
          return -1;
        }
        if (a.date_time < b.date_time) {
          return 1;
        }
        return 0;
      });
      return procedureProtocolForm.value[0].protocol;
    } else {
      if (this.fetchedTicket.ticket_procedure_list) {
        let name = this.fetchedTicket.ticket_procedure_list[index]?.current_protocol ?? "Novo protocolo";
        return name != "" ? name : "Novo protocolo";
      } else {
        return "Novo protocolo";
      }
    }
  }

  get proceduresForm() {
    return this.formGroup.get('ticket_procedure') as FormArray;
  }

  getProcedureFormGroup(index: number) {
    return this.proceduresForm.controls[index] as FormGroup;
  }

  addProcedure() {
    let procedureFormGroup = this.fb.group({
      id: [],
      procedure_id: ['', Validators.required],
      status_id: ['', Validators.required],
      refund_request_date: [null],
      invoice_value: [0],
      invoice_number: [],
      refunded_amount: [0],
      refund_date: [null],
      receipt_date: [null],
      co_participation_value: [0],
      transfer_value: [0],
      attachment: this.fb.array([]),
      protocol: this.fb.array([]),
      medical_orders: this.fb.array([]),
      medical_order_ids: this.fb.array([]),
      medical_reports: this.fb.array([]),
      medical_report_ids: this.fb.array([]),
      is_active: [true]
    });
    this.proceduresForm.push(procedureFormGroup);
  }

  removeProcedure(at: number) {
    let formGroup = this.proceduresForm.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({is_active: false});
    } else {
      this.proceduresForm.removeAt(at);
    }
  }

  procedureIsActive(index: number) {
    let formGroup = this.proceduresForm.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  createMedicalOrder(index: number) {
    let formArray = this.getProcedureFormGroup(index).get('medical_order_ids') as FormArray;
    formArray.push(this.fb.control([]));
  }

  createMedicalReport(index: number) {
    let formArray = this.getProcedureFormGroup(index).get('medical_report_ids') as FormArray;
    formArray.push(this.fb.control([]));
  }

  getLogFormGroup() {
    return this.formGroup.get('log') as FormGroup;
  }

  createAttachmentForm() {
    return this.fb.group({
      model_name: [''],
      filename: [''],
      selected_attachment_id: [''],
    });
  }

  get logAttachmentForm() {
    return this.getLogFormGroup().get('attachment') as FormArray;
  }

  createLogAttachment() {
    return this.fb.group({
      temporary_file_id: [''],
      filename: [''],
    });
  }

  get ticketAttachmentForm() {
    return this.formGroup.get('attachment') as FormArray;
  }

  createTicketAttachment() {
    return this.fb.group({
      id: [''],
      temporary_file_id: [''],
      filename: [''],
      is_contract: [false]
    });
  }

  createProtocol() {
    return this.fb.group({
      id: [''],
      date_time: [''],
      protocol: [''],
      is_active: ['']
    });
  }

  getHealthInsurance(ev: any) {
    this.formGroup.get('health_insurance_plan_id')?.reset();
    if (ev.health_insurances.length) {
      this.healthInsuranceList = ev.health_insurances;
    } else {
      this.healthInsuranceList = [];
    }
  }

  cancelHandler() {
    this.closeTicket();
  }

  closeTicket() {
    if (!this.id) {
      this.activeModal.close();
    } else {
      this.financialService.getTicketCard(this.id).subscribe(response => {
        console.log(response);
        this.activeModal.close(response);
      });
    }
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let ticketData: any = this.formGroup.getRawValue();
    if (ticketData.date_time)
      ticketData.date_time = moment(ticketData.date_time).format();

    if (!this.currentTaskFormGroup.get('task_id')?.value) {
      ticketData['current_task'] = null;
    }

    ticketData.ticket_procedure.forEach((procedure: any) => {
      if (procedure.refund_date) {
        procedure.refund_date = moment(procedure.refund_date).format();
      } else {
        procedure.refund_date = undefined;
      }
      if (procedure.refund_request_date) {
        procedure.refund_request_date = moment(procedure.refund_request_date).format();
      } else {
        procedure.refund_request_date = undefined;
      }
      if (procedure.receipt_date) {
        procedure.receipt_date = moment(procedure.receipt_date).format();
      } else {
        procedure.receipt_date = undefined;
      }
      if (procedure.attachment) {
        procedure.attachment.forEach((attachment: any) => {
          attachment.filename = undefined;
        });
      }
    });
    if (ticketData.attachment) {
      ticketData.attachment.forEach((attachment: any) => {
        attachment.filename = undefined;
      });
    }
    if (ticketData.log.attachment) {
      ticketData.log.attachment.forEach((attachment: any) => {
        attachment.filename = undefined;
      });
    }
    if (this.formGroup.valid) {
      if (this.isRegister) {
        this.ticketRegister(ticketData);
      } else {
        this.ticketEdit(ticketData);
      }
    } else {
      this.submitting = false;
    }
  }

  ticketRegister(ticketData: TicketPostModel) {
    this.financialService.financialTicketRegister(ticketData).subscribe(response => {
      this.toast.success('Ticket criado com sucesso', 'Sucesso');
      this.id = response.id;
      this.closeTicket();
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    });
  }

  ticketEdit(ticketData: TicketPostModel) {
    ticketData.id = this.id;
    this.financialService.financialTicketEdit(ticketData).subscribe(response => {
      this.toast.success('Ticket alterado com sucesso', 'Sucesso');
      this.closeTicket();
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    });
  }

  openAttachmentHistoric(addNewAttachment: boolean, type: String, name: String, id: any, items: any) {
    const modalRef = this.modalService.open(AttachmentModalComponent, {centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.modalItems = {id: id, name: name, items: items};
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.addNewAttachment = addNewAttachment;
    modalRef.result.then(result => {
    });
  }

  openAttachmentModal(addNewAttachment: boolean, attachmentOwner: string, index: number = 0) {
    let name: string = this.patientList.find((patient) => patient.id == this.formGroup.get('patient_id')?.value)?.name ?? '';
    let patientId = this.formGroup.get('patient_id')?.value;
    let items: any = '';
    let type: string = '';
    let id: any = index ?? '';
    let attachments: any = '';
    let medical_orders: any[] = [];
    let medical_reports: any[] = [];
    if (this.fetchedTicket.ticket_procedure_list && index < this.fetchedTicket.ticket_procedure_list!.length) {
      medical_orders = this.fetchedTicket.ticket_procedure_list![index].medical_orders!;
      medical_reports = this.fetchedTicket.ticket_procedure_list![index].medical_reports!;
    }
    let procedureAttachmentForm = this.getProcedureFormGroup(index).get('attachment') as FormArray;

    if (attachmentOwner == 'procedure') {
      type = "Procedimentos";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.ticket_procedure_list) {
          let procedures = this.fetchedTicket.ticket_procedure_list;
          if (procedures[index]?.attachment_list) {
            items = procedures[index].attachment_list;
          }
        }
      }
      if (procedureAttachmentForm.length > 0) {
        attachments = procedureAttachmentForm.value;
      }
    } else if (attachmentOwner == 'log') {
      type = "Histórico";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.log_list) {
          let logs = this.fetchedTicket.log_list;
          // items = logs[index].attachment_list;
        }
      }
      if (this.logAttachmentForm.length > 0) {
        attachments = this.logAttachmentForm.value;
      }
    } else {
      type = "Ticket";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.attachment_list) {
          items = this.fetchedTicket.attachment_list;
        }
      }
      if (this.ticketAttachmentForm.length > 0) {
        attachments = this.ticketAttachmentForm.value;
      }
    }

    const modalRef = this.modalService.open(AttachmentModalComponent, {centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.modalItems = {id, name, patientId, items, attachments, medical_orders, medical_reports};
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.addNewAttachment = addNewAttachment;
    modalRef.result.then(result => {
      let procedureFormGroup = this.getProcedureFormGroup(index);

      let medicalOrderIdsFormArray = procedureFormGroup!.get('medical_order_ids') as FormArray;
      medicalOrderIdsFormArray.clear();
      if (result.medicalOrderIds) result.medicalOrderIds.forEach((item: any) => medicalOrderIdsFormArray.push(this.fb.control([])));

      let medicalReportIds = procedureFormGroup!.get('medical_report_ids') as FormArray;
      medicalReportIds.clear();
      if (result.medicalReportIds) result.medicalReportIds.forEach((item: any) => medicalReportIds.push(this.fb.control([])));

      procedureFormGroup.patchValue({
        'medical_order_ids': result.medicalOrderIds,
        'medical_report_ids': result.medicalReportIds
      });

      if (result.attachmentsController) {
        let responseAttachments: AttachmentPostModel[] = result.attachmentsController;
        if (responseAttachments.length > 0) {
          if (attachmentOwner == 'procedure') {
            procedureAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              if (attachment.model_name == 'TEMPORARY_FILE') {
                let attachmentForm = this.createAttachmentForm();
                attachmentForm.patchValue(attachment);
                procedureAttachmentForm.push(attachmentForm);
              }
            });
          } else if (attachmentOwner == 'log') {
            this.logAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              let attachmentForm = this.createLogAttachment();
              attachmentForm.patchValue(attachment);
              this.logAttachmentForm.push(attachmentForm);
            });
          } else {
            this.ticketAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              let attachmentForm = this.createTicketAttachment();
              attachmentForm.patchValue(attachment);
              this.ticketAttachmentForm.push(attachmentForm);
            });
          }
        }
      }
    });
  }

  openMailingModal() {
    const modalRef = this.modalService.open(MailingModalComponent, {centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.ticket = this.fetchedTicket;
    modalRef.result.then(result => {
    });
  }

  openProtocolModal(addNewProtocol: boolean, index: number) {
    let procedureProtocolForm = this.getProcedureFormGroup(index).get('protocol') as FormArray;
    let id = this.getProcedureFormGroup(index).get('procedure_id')?.value;
    let name = this.proceduresList.find(value => value.id == id)?.name;
    let items: any = '';
    if (procedureProtocolForm.value != '') {
      items = procedureProtocolForm.value;
    } else {
      if (this.fetchedTicket) {
        if (this.fetchedTicket.ticket_procedure_list) {
          let procedures = this.fetchedTicket.ticket_procedure_list;
          if (procedures[index]?.protocol_list) {
            items = procedures[index].protocol_list;
          }
        }
      }
    }
    const modalRef = this.modalService.open(ProtocolModalComponent, {centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.modalItems = {name: name, items: items};
    modalRef.componentInstance.addNewProtocol = addNewProtocol;
    modalRef.result.then(result => {
      if (result.protocol) {
        procedureProtocolForm.clear();
        result.protocol.forEach((protocol: any) => {
          let protocolForm = this.createProtocol();
          protocolForm.patchValue(protocol);
          procedureProtocolForm.push(protocolForm);
        });
      }
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "date_time", "user_id", "patient_id", "health_insurance_plan_id",
      "attachment", "ticket_procedure", "log", "professional_id"
    ];
    let logErrNames = [
      "protocol", "notes"
    ];
    let procedureErrNames = [
      "procedure_id", "status_id", "refund_request_date", "invoice_value",
      "refunded_amount", "refund_date", "co_participation_value", "transfer_value", "protocol"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({response: errorResponse.error[name]});
    });
    if (errorResponse.error.log) {
      logErrNames.forEach(name => {
        if (errorResponse.error[name])
          this.getLogFormGroup().get(name)?.setErrors({response: errorResponse.error[name]});
      });
    }
    if (errorResponse.error.ticket_procedure) {
      let procedures: [] = errorResponse.error.ticket_procedure;
      Object.keys(procedures).map((index) => {
        let formProcedure = this.proceduresForm.controls[Number(index)] as FormGroup;
        let errorResponseProcedure = procedures[Number(index)];
        procedureErrNames.forEach(name => {
          if (errorResponseProcedure[name])
            formProcedure.get(name)?.setErrors({response: errorResponseProcedure[name]});
        });
      })
    }
  }

  fetchFinancialTasks() {
    this.financialService.listTasks().subscribe(response => {
      this.taskList = response;
    }, error => {
      console.error(error);
    });
  }

  getTask(id?: number): FinancialTask | undefined {
    return this.taskList.find(el => el.id == id);
  }

  allTasksCompleted(): boolean {
    let tasks = this.fetchedTicket.tasks?.map(taskRelation => taskRelation.task_completed) || [];
    let hasPendingTask = tasks.some(taskRelation => taskRelation == false);
    return !hasPendingTask;
  }

  showTaskForm = false;

  finishTask(checked: any) {
    let tasks = this.fetchedTicket.tasks || [];
    tasks.forEach(el => el.task_completed = true);
    this.showTaskForm = checked;
    this.currentTaskFormGroup.get('task_id')?.addValidators([Validators.required]);
    this.currentTaskFormGroup.patchValue({
      id: null,
      task_id: null,
      due_date: null,
      task_completed: false,
      ticket_id: this.fetchedTicket.id,
    });
  }

  dueDateIsRequired = false;

  taskSelected(ev: any) {
    this.dueDateIsRequired = false;
    if (ev && ev['has_due_date'] == true) {
      this.dueDateIsRequired = true;
    }
  }
}
