import {FinancialStatus} from "./financial-status.model";
import {Ticket} from "./ticket.model";
import * as moment from "moment";

export class Kanban {
  columns: FinancialStatus[] = [];
  cards: {[key: number]: Ticket[]} = [];
  statusList: FinancialStatus[] = [];

  private columnMapping: {[key: number]: number} = {};

  addColumn(column: FinancialStatus) {
    let columnExists = this.columns.some(el => el.order == column.order);
    if (!columnExists) {
      this.columns.push(column);
    } else {
      let existingColumn = this.columns.find(el => el.order == column.order);
      let nameLength = existingColumn?.name?.length || 99;
      let currentNameLength = column.name?.length || 99;

      if (currentNameLength < nameLength) {
        let index = this.columns.indexOf(existingColumn!);
        this.columns.splice(index, 1);
        this.columns.push(column);
      }
    }
    this.columnMapping[column.id!] = column.order!;
    this.cards = [];
    this.columns.forEach(_ => this.cards[_.order!] = []);
  }

  addCard(card: Ticket) {
    let minorStatus = this.getMinorStatus(card.detail_status || []);
    if (!minorStatus) {
      return;
    }
    let order = this.columnMapping[minorStatus!.id!];
    if (!order) {
      return;
    }
    this.cards[order].push(card);
    this.cards[order] = this.cards[order].sort((a, b) => {
      if (moment(a.date_time).isAfter(b.date_time)) {
        return -1;
      } return 1;
    });
  }

  removeCard(ticketId: number) {
    for (let index in this.cards) {
      this.cards[index] = this.cards[index].filter(el => el.id != ticketId);
    }
  }

  getStatusById(id: number) {
    return this.statusList.find(status => status.id == id);
  }

  private getMinorStatus(statuses: number[]) {
    let relatedStatusIds: number[] = statuses?.filter(_id => this.getStatusById(_id)) || [];
    let relatedStatuses: FinancialStatus[] = relatedStatusIds.map(_id => this.getStatusById(_id)!);
    relatedStatuses = relatedStatuses.filter(status => status.order != null);
    relatedStatuses = relatedStatuses.sort((a, b) => {
      if (a.order! < b.order!) {
        return -1;
      }
      return 1;
    });
    if (relatedStatuses.length > 0)
      return relatedStatuses[0];
    return null;
  }
}
