import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-login-health-insurance',
  templateUrl: './login-health-insurance.component.html',
  styleUrls: ['./login-health-insurance.component.scss']
})
export class LoginHealthInsuranceComponent implements OnInit {

  loadingPage: boolean = false;
  loading: boolean = false;
  submitLoading: boolean = false;

  loginForm!: FormGroup;

  patient_id!: string;
  ticket_id!: string;
  imageURL: string = '';

  constructor(private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private router: Router, private accountsService: AccountsService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.loadingPage = true;
    this.patient_id = this.sessionManager.patientData.patient_id;
    this.ticket_id = this.sessionManager.patientData.ticket_id;

    this.accountsService.getHealthInsurancePatient(Number(this.ticket_id)).subscribe(response => {
      this.imageURL = response.file_url ?? '';
    }, (error: HttpErrorResponse) => {
      this.toast.error(error.error['detail'], 'Erro');
    });

    this.loginForm = new FormGroup({
      "login": new FormControl(null, [Validators.required]),
      "password": new FormControl(null, [Validators.required, Validators.minLength(3)]),
    });
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.loginRequest();
    e.preventDefault();
  }

  loginRequest() {
    var accountData = {
      login: this.loginForm.get('login')!.value,
      password: this.loginForm.get('password')!.value,
      ticket_id: this.ticket_id,
    };

    this.accountsService.credentialsPatient(accountData, Number(this.patient_id)).subscribe(response => {
      this.toast.success('Sucesso');
      this.loading = false;
      location.href = 'https://reembolsogarantido.com.br/sucesso';
    }, (error: HttpErrorResponse) => {
      this.toast.error(error.error['detail'], 'Erro');
    });
  }


}
