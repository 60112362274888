<div class="row search m-4" [formGroup]="searchFormGroup" *ngIf="isLoadingPage">
    <div class="col-3 pl-0">
        <app-select-input id="user" [formGroup]="searchFormGroup" bindLabel="name" bindValue="id" [items]="usersListOptions"
        [multiple]="false" placeholder="Todos os usuários" (onValueChange)="filterReports()"></app-select-input>
    </div>

    <div class="col-3 p-0 search_date">
        <div class="row p-0 m-0" [formGroup]="searchFormGroup">
            <input type="date" class="col error" [class.error]="searchFormGroup.get('since')?.errors && searchFormGroup.get('until')?.errors" 
            formControlName="since" (change)="filterByDate()"/>
            <span class="col-1 d-flex align-self-center justify-content-center p-0">até</span>
            <input type="date" class="col error" [class.error]="searchFormGroup.get('until')?.errors && searchFormGroup.get('since')?.errors" 
            formControlName="until" (change)="filterByDate()"/>
        </div>
    </div>
    
    <div class="col-2 d-flex">
        <app-select-input id="action" [formGroup]="searchFormGroup" bindLabel="name" bindValue="id" placeholder="Ação"
        (onValueChange)="filterReports()" [multiple]="false" [items]="actionsList"></app-select-input>
    </div>
    
    <div class="col-3 p-0 search_date row p-0 m-0">          
        <span id="records" class="d-flex align-self-center justify-content-center p-0"> {{counts}} registros encontrados</span>
    </div>

</div>

<div class="card my-2">
    <div *ngIf="isLoading" class="text-center spinner">
        <span class="spinner-border spinner-border-xl"></span>
    </div>

    <div *ngIf="!isLoading && reports.length == 0 && !invalidDate" class="text-center spinner">
        <span>Nenhum dado foi encontrado.</span>
    </div>
    <div *ngIf="!isLoading && invalidDate" class="text-center spinner">
        <span class="text-invalid">Período inválido.</span>
    </div>
    
    

    <table class="table table-bordered col-12 m-0" *ngIf="!isLoading && reports.length > 0 && !invalidDate">        
            <thead class="main-head col-12">
                <tr>
                    <th class="col-1 orderable" (click)="orderBy('ticket__date_time')">
                        DATA DE ATENDIMENTO
                        <i *ngIf="orderedBy('ticket__date_time')" class="icon-caret-down" [class.ascending]="isAscending"></i>
                    </th>
                    <th class="col-2 orderable" (click)="orderBy('patient__name')">
                        PACIENTE
                        <i *ngIf="orderedBy('patient__name')" class="icon-caret-down" [class.ascending]="isAscending"></i>
                    </th>
                    <th class="col-2 orderable" (click)="orderBy('user__name')">
                        USUÁRIO
                        <i *ngIf="orderedBy('user__name')" class="icon-caret-down" [class.ascending]="isAscending"></i>
                    </th>
                    <th class="col-1 orderable" (click)="orderBy('created_at')">
                        DATA E HORA DE ALTERAÇÃO
                        <i *ngIf="orderedBy('created_at')" class="icon-caret-down" [class.ascending]="isAscending"></i>
                    </th>
                    <th class="col-1">ANEXO</th>
                    <th class="col-1">PROTOCOLO</th>
                    <th class="col-2 orderable" (click)="orderBy('action')">
                        Ação
                        <i *ngIf="orderedBy('action')" class="icon-caret-down" [class.ascending]="isAscending"></i>
                    </th>
                </tr>
            </thead>

            
            <tbody *ngFor="let report of reports;" let ticketIndex = index, class="main-body">
                <tr class="report-row" (click)="openTicket(report.ticket_id!)">
                    <td [title]="report?.ticket__date_time">{{dateHandle(report?.ticket__date_time!, false)}}</td>
                    <td [title]="report?.patient__name">{{report?.patient__name}}</td>
                    <td [title]="report?.user__name">{{report?.user__name}}</td>
                    <td [title]="report?.created_at">{{dateHandle(report?.created_at!, true)}}</td>
                    <td>
                        <span *ngIf="report.had_attachment" [title]="report?.had_attachment">
                            <i class="icon-check"></i>
                        </span>                        
                    </td>
                    <td>
                        <span *ngIf="report.had_procedure_protocol" [title]="report?.had_procedure_protocol">
                            <i class="icon-check"></i>
                        </span>                        
                    </td>
                    <td [title]="report?.method">{{report?.method}}</td>
                </tr>
            </tbody>           

        
    </table>
        <div class="col-14 align-items-center pt-2 pb-2" *ngIf="hasNext.next">
            <div *ngIf="hasNext.loading" class="text-center spinner">
            <span class="spinner-border spinner-border-xl"></span>
            </div>

            <button *ngIf="!hasNext.loading" class="text-center orange" (click)="loadMore()" [disabled]="hasNext.loading">
            + Clique para exibir mais
            </button>
        </div>
</div>