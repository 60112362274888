import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialTableComponent } from './financial-table/financial-table.component';
import { FinancialFormComponent } from './financial-form/financial-form.component';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '../navigation/navigation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { FinancialReportComponent } from './financial-report/financial-report.component';
import { KanbanComponent } from './kanban/kanban.component';
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [
    FinancialTableComponent,
    FinancialFormComponent,
    FinancialReportComponent,
    KanbanComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    NgSelectModule,
    NgxMaskModule,
    ReactiveFormsModule,
    SharedModule,
    CurrencyMaskModule,
    NgbModule
  ]
})
export class FinancialModule { }
