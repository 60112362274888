import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountModel } from '../models/account.model';
import { HealthInsuranceAppModel } from '../models/health-insurances-app';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpClient: HttpClient) { }

  login(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/login/`, JSON.stringify(accountData));
  }

  accountGetAll(isActive: string = 'true'): Observable<AccountModel[]> {
    return this.httpClient.get<AccountModel[]>(`${environment.API_URL}/accounts/all/?is_active=${isActive}`);
  }

  getHealthInsurancePatient(ticket_id: number): Observable<HealthInsuranceAppModel> {
    return this.httpClient.get<HealthInsuranceAppModel>(`${environment.API_URL}/app/health_insurances/?ticket_id=${ticket_id}`);
  }

  credentialsPatient(credencials: any, patientId: number): Observable<any> {
    return this.httpClient.patch(`${environment.API_URL}/app/patients/${patientId}/ticket_refund/synchronization/`, credencials);
  }
}
