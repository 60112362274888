<div class="modal-body">
  <button class="btn btn-closed col-1" (click)="activeModal.close(false)">
    <i class="icon-close"> </i>
  </button>

  <form [formGroup]="formGroup">
    <!--<app-basic-input [formGroup]="formGroup" controlName="to" label="Destinatário"></app-basic-input>-->

    <label for="email">Corpo do e-mail</label>
    <editor [init]="config" formControlName="email"></editor>
  </form>
</div>
