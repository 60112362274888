import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import {StatusStage} from "../../../../core/models/status-stage.model";
import {FinancialService} from "../../../../core/services/financial.service";
import {Ticket} from "../../../../core/models/ticket.model";
import {FinancialStatus} from "../../../../core/models/financial-status.model";
import {Kanban} from "../../../../core/models/kanban";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TicketComponent} from "../../../../shared/modals/ticket/ticket.component";
import {ActivatedRoute} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {HealthInsuranceModel} from "../../../../core/models/health-insurance.model";
import {OriginModel} from "../../../../core/models/origin.model";
import {AccountModel} from "../../../../core/models/account.model";
import {AccountsService} from "../../../../core/services/accounts.service";
import * as moment from "moment";

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit {
  kanban = new Kanban();

  statusStageList: StatusStage[] = [];
  statusList: FinancialStatus[] = [];
  ticketList: Ticket[] = [];
  healthInsurances: HealthInsuranceModel[] = [];
  patientOrigins: OriginModel[] = [];
  userList: AccountModel[] = [];

  fetchingStatusStages = false;
  fetchingStatus = false;
  fetchingTickets = false;
  fetchingHealthInsurances = false;
  fetchingOrigins = false;
  fetchingUsers = false;

  searchFormGroup = this.fb.group({
    status: [[]],
    user: [[]],
    since: [],
    until: [],
    due_date: [],
    patient__name: [],
    patient__document_number: [],
    health_insurances: [[]],
    origin: [[]]
  });

  constructor(private service: FinancialService, private modal: NgbModal, private activatedRoute: ActivatedRoute,
              private fb: FormBuilder, private accountService: AccountsService, private location: Location) {
  }

  get isLoading(): boolean {
    return this.fetchingStatusStages && this.fetchingStatus && this.fetchingTickets && this.fetchingHealthInsurances &&
      this.fetchingOrigins && this.fetchingUsers;
  }

  ngOnInit(): void {
    this.fetchTickets();
    this.fetchStatuses();
    this.fetchStatusStages();
    this.fetchHealthInsurances();
    this.fetchPatientOrigins();
    this.fetchUsersList();
  }

  fetchUsersList() {
    this.fetchingUsers = true;
    this.accountService.accountGetAll().subscribe(response => {
      this.userList = response;
      this.fetchingUsers = true;
    });
  }

  fetchHealthInsurances() {
    this.fetchingHealthInsurances = true;
    this.service.healthInsuranceGetAll().subscribe(response => {
      this.healthInsurances = response;
      this.fetchingHealthInsurances = false;
    });
  }

  fetchStatusStages() {
    this.fetchingStatusStages = true;
    this.service.financialStatusAll().subscribe(response => {
      this.fetchingStatus = false;
      this.statusList = response;
    });
  }

  fetchStatuses() {
    this.fetchingStatus = true;
    this.service.financialStatusAll().subscribe(response => {
      this.fetchingStatus = false;
      this.statusList = response;
      this.kanban.statusList = response;
      let filteredStatusList = response.filter(status => status.order);
      filteredStatusList.forEach(status => this.kanban.addColumn(status));
    });
  }

  fetchTickets() {
    this.fetchingTickets = true;
    this.service.listAllFinancialTickets().subscribe(response => {
      this.fetchingTickets = false;
      this.ticketList = response;
      this.ticketList.forEach(ticket => {
        this.kanban.addCard(ticket);
      });

      this.activatedRoute.params.subscribe(params => {
        if (params['id']) {
          let ticket = this.ticketList.find(el => el.id == params['id']);
          if (ticket) {
            this.openTicket(ticket);
          }
        }
      });
    });
  }

  fetchPatientOrigins() {
    this.fetchingOrigins = true;
    this.service.getAllPatientOrigins().subscribe(response => {
      this.patientOrigins = response;
      this.fetchingOrigins = false;
    });
  }

  openTicket(ticket?: Ticket) {
    let modal = this.modal.open(TicketComponent);

    if (ticket) {
      this.location.replaceState(`/dashboard/kanban/${ticket?.id}`);
      modal.componentInstance.id = ticket.id;
    }

    modal.result.then((updatedTicket) => {
      if (updatedTicket) {
        this.kanban.removeCard(updatedTicket.id);
        this.kanban.addCard(updatedTicket);
      }
    });
  }

  getFirstWord(str: string | undefined) {
    if (!str) return '';
    let splitted = str.split(' ');
    return splitted[0];
  }

  _getSearch(field: string): string {
    return this.searchFormGroup.get(field)?.value?.toLowerCase() || '';
  }

  showTicket(ticket: Ticket): boolean {
    if (this._getSearch('patient__name') != '') {
      if (!ticket.patient__name?.toLowerCase().includes(this._getSearch('patient__name'))) {
        return false;
      }
    }

    if (this._getSearch('patient__document_number') != '') {
      if (!ticket.patient__document_number?.toLowerCase().includes(this._getSearch('patient__document_number'))) {
        return false;
      }
    }

    let healthInsurances: any = this.searchFormGroup.get('health_insurances')?.value;
    if (healthInsurances.length > 0 && !healthInsurances.includes(ticket.health_insurance_plan?.health_insurance?.id)) {
      return false;
    }

    let origin: any = this.searchFormGroup.get('origin')?.value;
    if (origin.length > 0 && !origin.includes(ticket.patient?.ad_origin) && !origin.includes(ticket.patient?.clinic?.display_name)) {
      return false;
    }

    let since = this.searchFormGroup.get('since')?.value;
    if (since && ticket.date_time! < since) {
      return false;
    }

    let users: any = this.searchFormGroup.get('user')?.value;
    if (users.length > 0 && !users.includes(ticket.user?.id)) {
      return false;
    }

    let until = this.searchFormGroup.get('until')?.value;
    if (until && ticket.date_time! > until) {
      return false;
    }

    let due_date = this.searchFormGroup.get('due_date')?.value;
    if (due_date && !this.hasPendingTasks(ticket, due_date)) {
      return false;
    }

    return true;
  }

  hasPendingTasks(ticket: Ticket, due_date: Date): boolean {
    let tasks = ticket.tasks || [];
    let pending_taks = tasks.filter(_task => !_task.task_completed && _task.due_date === due_date);
    return pending_taks.length > 0
  }

  getBackgroundColor(ticket: Ticket): string {
    let lateStatus = ticket.late_status;
    if (!lateStatus) {
      return '#FFFFFF';
    } else {
      let status = this.statusList.find(status => status.id == lateStatus?.status_id);
      if (!status) {
        return '#FFFFFF';
      } else {
        let date = moment();
        let desired = moment(lateStatus.date).add(status.desired_time, 'days');
        let deadline = moment(lateStatus.date).add(status.deadline, 'days');
        let deadline_background_color = status.deadline_background_color;
        let desired_time_background_color = status.desired_time_background_color;
        if (date >= deadline && deadline_background_color) {
          return deadline_background_color;
        } else if (date >= desired && desired_time_background_color) {
          return desired_time_background_color;
        } else {
          return '#FFFFFF';
        }
      }
    }
  }

  getFontColor(ticket: Ticket): string {
    let lateStatus = ticket.late_status;
    if (!lateStatus) {
      return '#212529';
    } else {
      let status = this.statusList.find(status => status.id == lateStatus?.status_id);
      if (!status) {
        return '#212529';
      } else {
        let date = moment();
        let desired = moment(lateStatus.date).add(status.desired_time, 'days');
        let deadline = moment(lateStatus.date).add(status.deadline, 'days');
        let deadline_font_color = status.deadline_font_color;
        let desired_time_font_color = status.desired_time_font_color;
        if (date >= deadline && deadline_font_color) {
          return deadline_font_color;
        } else if (date >= desired && desired_time_font_color) {
          return desired_time_font_color;
        } else {
          return '#212529';
        }
      }
    }
  }

  getBulletsForTicket(ticket: Ticket, exclude: any) {
    let statuses = ticket.detail_status || [];
    let colors: string[] = [];
    statuses.forEach(_id => {
      let color = this.getBulletColor(_id);
      if (!colors.includes(color)) {
        colors.push(color);
      }
    });
    return colors.filter(color => color != exclude);
  }

  getBulletColor(statusId: any): string {
    let status = this.statusList.find(el => el.id == statusId);
    if (!status || !status.background_color) {
      return '#ffffff';
    }
    return status.background_color;
  }

  filterTicketListAndOrder() {
  }

  isTaskCompletedForToday(ticket: Ticket): boolean {
    let today = moment();
    let tasks = ticket.tasks || [];
    let todayTasks = tasks.filter(_task => today.isSame(_task.due_date, 'day'));

    if (todayTasks.length > 0) {
      return todayTasks.some(_task => _task.task_completed);
    }

    return false;
  }

  hasPendingTaskForToday(ticket: Ticket): boolean {
    let today = moment();
    let tasks = ticket.tasks || [];
    let todayTasks = tasks.filter(_task => today.isSame(_task.due_date, 'day'));

    if (todayTasks.length > 0) {
      return todayTasks.some(_task => !_task.task_completed);
    }

    return false;
  }

  hasLateTask(ticket: Ticket): boolean {
    let today = moment();
    let tasks = ticket.tasks || [];
    let lateTasks = tasks.filter(_task => !_task.task_completed && today.isAfter(_task.due_date, 'day'))

    return lateTasks.length > 0;
  }

  pendingValueInColumn(status: FinancialStatus) {
    let cards: Ticket[] = this.kanban.cards[status.order!].filter(el => this.showTicket(el));
    let value = 0;
    cards.forEach(el => value += parseFloat(el.pending_amount || '0'));
    return value;
  }

  countFilteredColumn(status: FinancialStatus) {
    let cards = this.kanban.cards[status.order!];
    cards = cards.filter(el => this.showTicket(el));
    return cards.length;
  }
}
