import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loadingPage: boolean = false;
  loading: boolean = false;
  submitLoading: boolean = false;

  loginForm!: FormGroup;

  constructor(private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private router: Router, private accountsService: AccountsService, private toast: ToastrService) { }


  ngOnInit(): void {
    this.loadingPage = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['account']) {
        let data = JSON.parse(window.atob(params['account']));
        this.sessionManager.setSessionData(data);
        this.router.navigate(['/dashboard']);
        this.loadingPage = false;
        return;
      } else if (params['token'] && params['patient_id'] && params['ticket_id']) {
        let data = {
          token: params['token'],
          patient_id: params['patient_id'],
          ticket_id: params['ticket_id']
        };
        this.sessionManager.setSessionDataPatient(data);
        this.router.navigate(['/loginPatient']);
        this.loadingPage = false;
      } else {
        let url = environment.ELEVE_URL;
        window.open(url, '_self');
        return;
      }
    });
    //this.loginForm = new FormGroup({
      //"email": new FormControl(null, [Validators.required, Validators.email]),
      //"password": new FormControl(null, [Validators.required, Validators.minLength(3)]),
    //});
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.loginRequest();
    e.preventDefault();
  }

  loginRequest() {
    var accountData = {
      email: this.loginForm.get('email')!.value,
      password: this.loginForm.get('password')!.value
    } as AccountModel;

    this.accountsService.login(accountData).subscribe((response: AccountModel) => {
      this.sessionManager.setSessionData(response);
      this.router.navigate(["/dashboard"]);
      this.loading = false;
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      this.mapErrorResponse(error);
      console.log(error)
    });
  }

  mapErrorResponse(error: HttpErrorResponse) {
    if(error.error["detail"]) {
      this.toast.error(error.error["detail"], "Erro");
    } else {
      let errNames = [
        "email", "password"
      ];
      errNames.forEach(name => {
        console.log(error.error[name])
        if (error.error[name])
          this.loginForm.get(name)?.setErrors({ response: error.error[name] });
      });
    }
  }

}
