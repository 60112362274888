import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FinancialService } from 'src/app/core/services/financial.service';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { Router, NavigationExtras } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/pt';
import { FinancialReportModel } from 'src/app/core/models/financial-report.model';
import { AccountModel } from 'src/app/core/models/account.model';
import { Options } from 'src/app/shared/models/options.model';
import * as moment from 'moment';
import { map, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FinancialFormComponent } from '../financial-form/financial-form.component';

registerLocaleData(localeEs);


@Component({
  selector: 'app-financial-report',
  templateUrl: './financial-report.component.html',
  styleUrls: ['./financial-report.component.scss']
})
export class FinancialReportComponent implements OnInit {


  constructor(private fb: FormBuilder, private financialService: FinancialService, private accountsService: AccountsService,
    private modalService: NgbModal, private titleService: Title, private router: Router) { 
      this.titleService.setTitle('Reembolso Garantido - Relatorio Financeiro');
    }
    
  
  searchFormGroup = this.fb.group({      
      user: [],
      since: [],
      until: [],
      action: []
  });

  numberOfRecords?: number;


  searchAction: string = '';
  searchUser: string = '';
  searchDateSince: string = '';
  searchDateUntil: string = '';

  usersList: AccountModel[] = [];
  

  usersListOptions: Options[] = [];
  

  reports: FinancialReportModel[] = []
  filteredReport: FinancialReportModel[] = [];

  hasNext = {
    next: false,
    page: 1,
    loading: false
  };

  ordering: string = '-created_at';

  loading = false;

  invalidDate = false;

  actionsList = [
    { id: 'Criação', name: 'Criação' },
    { id: 'Edição', name: 'Edição' },
    { id: '', name:'Todos'}
  ]

  loadingUsers = false;
  loadingAction = false;
  loadingReport = false;


  subscription: Subscription | null = null;

  counts?: number;


  ngOnInit(): void {
    this.fetchReports();
    this.fetchUsersList();
  }


  get isLoading() {
    return this.loadingUsers || this.loadingAction || this.loadingReport;
  }

  get isLoadingPage() {
    return !this.loadingUsers;
  }

 

  fetchUsersList() {
    this.loadingUsers = true;
    this.accountsService.accountGetAll().subscribe(response => {
      this.usersList = response;
      this.usersListOptions = response.map(item => {
        return {
          id: item.id,
          name: item.name
        } as Options;
      });
      this.usersListOptions.unshift(
        {
          id: '', name: 'Todos os usuários'
        } 
      )
      this.loadingUsers = false;
    });
  } 
  
  filterReports() {

    this.searchUser = this.searchFormGroup.get('user')?.value ?? '';
    this.searchDateSince = this.searchFormGroup.get('since')?.value ?? '';
    this.searchDateUntil = this.searchFormGroup.get('until')?.value ?? '';
    this.searchAction = this.searchFormGroup.get('action')?.value ?? '';  

    this.fetchReports();

  }


  filterByDate() {
    this.searchDateSince = this.searchFormGroup.get('since')?.value ?? '';
    this.searchDateUntil = this.searchFormGroup.get('until')?.value ?? '';

    if (this.searchDateUntil != '' && this.searchDateSince > this.searchDateUntil) {
      this.searchFormGroup.get('since')?.setErrors({ invalid: true });
      this.searchFormGroup.get('until')?.setErrors({ invalid: true });
      this.invalidDate = true;
    } else {
      this.searchFormGroup.get('until')?.clearValidators();
      this.invalidDate = false;
            
    }
    this.fetchReports();
  }


  fetchReports() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.loadingReport = true;
    this.subscription = this.financialService.reportGet(this.searchUser, this.searchAction, this.searchDateSince, this.searchDateUntil,1 ,this.ordering).subscribe(response => {
      this.reports = response.results as FinancialReportModel[];
      this.counts = response.count;
      this.hasNext.next = response.next != null;
      this.hasNext.page = 1;
      this.loadingReport = false;
    });
  }

  loadMore() {
    this.hasNext.page++;
    this.financialService.reportGet(this.searchUser, this.searchAction, this.searchDateSince, this.searchDateUntil, this.hasNext.page).subscribe(
      response => {
        this.hasNext.next = response.next != null;
        console.log(response.results);
        response?.results?.forEach(result => {
          console.log(result);
          this.reports.push(result)
        })
      }
    )
  }


  get isAscending() {
    return !this.ordering.includes('-');
  }

  orderedBy(field: string) {
    return this.ordering.includes(field);
  }

  orderBy(field: string) {
    if (this.ordering == field) {
      this.ordering = `-${field}`;
    } else {
      this.ordering = field;
    }
    this.filterReports();
  }

  dateHandle(date: string, isDateTime: boolean) {
    if (date == undefined) return '';
    if (isDateTime) return moment(date).format('DD/MM/YYYY - HH:mm');
    else return moment(date).format('DD/MM/YYYY');
  }
  
  
  openTicket(ticket_id: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/dashboard/financial/report/edit/${ticket_id}`])
    );
    window.open('#' + url, '_blank');
  }

}

