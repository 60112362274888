import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FinancialReportModel } from '../models/financial-report.model';
import { FinancialStatus } from '../models/financial-status.model';
import { HealthInsuranceModel } from '../models/health-insurance.model';
import { ListModel } from '../models/list.model';
import { OriginModel } from '../models/origin.model';
import { PatientModel } from '../models/patient.model';
import { ProcedureModel } from '../models/procedure.model';
import { ProfessionalModel } from '../models/professional.model';
import { StatusStage } from '../models/status-stage.model';
import { TicketPostModel } from '../models/ticket-post.model';
import { Ticket } from '../models/ticket.model';
import {FinancialTask} from "../models/financial-task.model";

@Injectable({
  providedIn: 'root'
})
export class FinancialService {

  constructor(private httpClient: HttpClient) { }

  statusStageAll(): Observable<StatusStage[]> {
    return this.httpClient.get<StatusStage[]>(`${environment.API_URL}/financial/status_stages/all/?is_active=true`);
  }

  financialStatusAll(is_active: boolean = true): Observable<FinancialStatus[]> {
    return this.httpClient.get<FinancialStatus[]>(`${environment.API_URL}/financial/statuses/all/?is_active=${is_active}/`);
  }

  healthInsuranceGetAll(): Observable<HealthInsuranceModel[]> {
    return this.httpClient.get<HealthInsuranceModel[]>(`${environment.API_URL}/health_insurances/all/`);
  }

  listAllFinancialTickets(active = true): Observable<Ticket[]> {
    if (active) {
      return this.httpClient.get<Ticket[]>(`${environment.API_URL}/financial/tickets/`);
    } else {
      return this.httpClient.get<Ticket[]>(`${environment.API_URL}/financial/tickets/done/`);
    }
  }

  financialTicketRegister(ticketData: TicketPostModel): Observable<TicketPostModel> {
    return this.httpClient.post<TicketPostModel>(`${environment.API_URL}/financial/tickets/`, JSON.stringify(ticketData));
  }

  financialTicketEdit(ticketData: TicketPostModel): Observable<TicketPostModel> {
    return this.httpClient.patch<TicketPostModel>(`${environment.API_URL}/financial/tickets/${ticketData.id}/`, JSON.stringify(ticketData));
  }

  financialTicketGetById(id: number): Observable<Ticket> {
    return this.httpClient.get<Ticket>(`${environment.API_URL}/financial/tickets/${id}/`);
  }

  getTicketCard(id: number): Observable<Ticket> {
    return this.httpClient.get<Ticket>(`${environment.API_URL}/financial/tickets/${id}/ticket_card/`);
  }

  financialTicketGet(search = '', status = '', user = '', dateSince = '', dateUntil = '', page = 1, filters: { [key: string]: string } = {}, ordering = '-date_time', active = true): Observable<Ticket[]> {
    if (active) {
      let queryParams = [];
      for (let key in filters) {
        if (filters[key]) {
          let queryParam = `${key}=${filters[key]}`;
          queryParams.push(queryParam);
        }
      }
      let queryParamsString = queryParams.join('&');
      return this.httpClient.get<Ticket[]>(`${environment.API_URL}/financial/tickets/?${queryParamsString}&status=${status}&user=${user}&since=${dateSince}&until=${dateUntil}&page=${page}&ordering=${ordering}`);
    } else {
      return this.httpClient.get<Ticket[]>(`${environment.API_URL}/financial/tickets/done/`);
    }
  }

  exportFinancialToCSV(ticketIds: string) {
    return this.httpClient.get(`${environment.API_URL}/financial/tickets/export/?ids=${ticketIds}`, { responseType: 'text' });
  }

  procedureGetAll(): Observable<ProcedureModel[]> {
    return this.httpClient.get<ProcedureModel[]>(`${environment.API_URL}/procedures/all/`);
  }

  uploadTemporaryFile(formData: FormData) {
    return this.httpClient.post(`${environment.API_URL}/temporary_files/`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  professionalGetAll(): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?ordering=name&is_active=true`);
  }

  reportGet(user = '', method = '', dateSince = '', dateUntil = '', page = 1, ordering='-created_at'): Observable<ListModel>{
    return this.httpClient.get<ListModel>(`${environment.API_URL}/financial/logs/?user_id=${user}&since=${dateSince}&until=${dateUntil}&method=${method}&page=${page}&ordering=${ordering}`);
  }


  // PATIENT

  patientGetAll(): Observable<PatientModel[]> {
    return this.httpClient.get<PatientModel[]>(`${environment.API_URL}/patients/all/?is_active=true`);
  }

  allExamsFromPatient(patientId: number): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_URL}/patients/${patientId}/patient_exams/all/`);
  }

  getAllPatientOrigins(): Observable<OriginModel[]> {
    return this.httpClient.get<OriginModel[]>(`${environment.API_URL}/financial/tickets/origins/`);
  }

  allDocumentsFromPatient(patientId: number): Observable<any> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/documents/all/`);
  }

  listTasks(): Observable<FinancialTask[]> {
    return this.httpClient.get<FinancialTask[]>(`${environment.API_URL}/financial/tasks/all/`);
  }
}
