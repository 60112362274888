import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ticket } from 'src/app/core/models/ticket.model';
import * as moment from 'moment';

@Component({
  selector: 'app-mailing-modal',
  templateUrl: './mailing-modal.component.html',
  styleUrls: ['./mailing-modal.component.scss']
})
export class MailingModalComponent implements OnInit {
  ticket?: Ticket;

  defaultMailMessage = '';

  formGroup = this.fb.group({
    to: [null, [Validators.required, Validators.email]],
    email: ['', [Validators.required]],
  });

  config = {
    apiKey: 'xil83bwdeel8wa7cv6hwplxgze6gvemn2wol5x2xt37o6tu6',
    plugins: 'table',
    toolbar: 'bold italic bullist',
    menubar: ''
  };

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.defaultMailMessage = 'Boa tarde,<br>' +
      'Espero que te encontre bem!<br>' +
      'Conforme conversamos, segue todos os dados referente ao protocolo dos pedidos de reembolso da ' +
      this.ticket?.health_insurance__name +
      ', de seu atendimento ambulatorial na ELEVE DIAGNÓSTICO, MEDICINA & ODONTOLOGIA na unidade da Ana Rosa/Vila ' +
      'Mariana:<br><br>' +
      this.defaultTable +
      '<br><br>' +
      'IMPORTANTE:<br>' +
      '<ul>' +
      '<li>Seguimos exatamente todo o protocolo de check list para documentos obrigatórios de reembolso, determinado pela operadora via site e o prazo para pagamento é XXXXXXXXX;</li>' +
      '<li>Todos os documentos seguiram legíveis, completos, com carimbo e assinatura dos profissionais e/ou empresas;</li>' +
      '<li>Pode confirmar que os documentos foram solicitados ao médico e a ELEVE DIAGNÓSTICO e submetidos ' + this.ticket?.health_insurance__name + ' por meio de seu acesso pessoal ao portal da operadora;</li>' +
      '<li>Se perguntado o porquê da escolha de realização do procedimento desta forma, pode responder que foi por escolha sua, com disponibilidade financeira e de agenda para a execução do procedimento o mais rápido possível, e por último porque lhe é oferecido por lei esse direito;</li>' +
      '<li>Acompanharemos juntos o processamento da ' + this.ticket?.health_insurance__name + ' e tão logo todos os pagamentos sejam liberados em sua conta corrente cadastrada, lhe informarei os próximos passos;</li>' +
      '<li>TODO E QUALQUER CONTATO DA ' + this.ticket?.health_insurance__name?.toUpperCase() + ' CONTIGO, por favor peça para que eles lhe retornem em horário noturno ou pegue com eles o número que possa retornar, não responda nada antes de nos falarmos para que eu possa te orientar; Portanto, por favor me avise todo e qualquer contato que façam;</li>' +
      '<li>Acredito que esteja tudo listado neste e-mail, contudo, caso precise de qualquer suporte de minha parte por favor me procure.</li>' +
      '</ul>';

    this.formGroup.patchValue({'email': this.defaultMailMessage});
  }

  get defaultTable() {
    let procedureList = this.ticket?.ticket_procedure_list || [];
    let table = '' +
      '<table border="1">' +
      '<tr><th>DATA DO SERVIÇO</th><th>NOME</th><th>CONVÊNIO</th><th>PROCEDIMENTO</th><th>VALOR DA NF EMITIDA</th><th>NOTA FISCAL</th><th>PROTOCOLO</th><th>STATUS</th></tr>';
    procedureList.map((el, index) => table = table.concat(this._buildProcedureRow(index)));
    table = table.concat('</table>');
    return table;
  }

  _buildProcedureRow(index: number) {
    let procedureList = this.ticket?.ticket_procedure_list || [];
    let procedure = procedureList[index];
    let invoice_number = procedure.invoice_number != null ? procedure.invoice_number ?? '' : '';
    let tableRow = '<tr>' +
      '<td>' + moment(this.ticket?.date_time).format('DD/MM/YYYY') + '</td>' +
      '<td>' + this.ticket?.patient?.name + '</td>' +
      '<td>' + this.ticket?.health_insurance__name + '</td>' +
      '<td>' + procedure.procedure__name + '</td>' +
      '<td>R$ ' + procedure.invoice_value?.toFixed(2).replace('.', ',') + '</td>' +
      '<td>' + invoice_number + '</td>' +
      '<td>' + procedure.current_protocol + '</td>' +
      '<td>' + procedure.status?.stage__name + '</td>' +
      '</tr>';
    return tableRow;
  }

}
