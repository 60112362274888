<div class="button-controller position-absolute">
  <a routerLink="/dashboard/financial" class="btn btn-link text-white">
    ver encerrados
  </a>

  <button class="btn btn-link btn-link--bold text-white" (click)="openTicket()">
    + Ticket
  </button>
</div>

<div class="row search" [formGroup]="searchFormGroup" *ngIf="!isLoading">
  <div class="col-2 p-0 pr-1">
    <app-basic-input placeholder="Nome do paciente" [formGroup]="searchFormGroup" controlName="patient__name"
                     (keyup)="filterTicketListAndOrder()"></app-basic-input>
  </div>

  <div class="col-1 p-0 pr-1">
    <app-basic-input placeholder="CPF" [maskOptions]="{mask: '00000000000'}" [formGroup]="searchFormGroup"
                     controlName="patient__document_number" (keyup)="filterTicketListAndOrder()"></app-basic-input>
  </div>

  <div class="col-2 p-0 pr-1">
    <ng-select class="custom" placeholder="Usuários" formControlName="user" [items]="userList"
               [multiple]="true" bindLabel="name" bindValue="id"></ng-select>
  </div>

  <div class="col p-0 pr-1 search_date" ngbTooltip="Data tarefa">
    <input  type="date" formControlName="due_date" (change)="filterTicketListAndOrder()">
  </div>

  <div class="col p-0 pr-1">
    <ng-select class="custom" placeholder="Convênio" formControlName="health_insurances" [items]="healthInsurances"
               [multiple]="true" bindLabel="name" bindValue="id"></ng-select>
  </div>

  <div class="col p-0 pr-1">
    <ng-select class="custom" placeholder="Indicação" formControlName="origin" [items]="patientOrigins" bindValue="name"
               [multiple]="true"></ng-select>
  </div>

  <div class="col-3 p-0 search_date" ngbTooltip="Data de atendimento">
    <div class="row p-0 m-0" [formGroup]="searchFormGroup">
      <input type="date" class="col" formControlName="since" (change)="filterTicketListAndOrder()"/>
      <span class="col-1 d-flex align-self-center justify-content-center p-0">até</span>
      <input type="date" class="col" formControlName="until" (change)="filterTicketListAndOrder()"/>
    </div>
  </div>

  <!--<div class="link col-2 p-0 m-0 d-flex justify-content-center">
    <button class="btn btn-link" *ngIf="!active" (click)="fetchTicketList(true)">Encerrados</button>
    <button class="btn btn-link" *ngIf="active" (click)="fetchTicketList(false)">Ativos</button>
    <button class="btn btn-link" (click)="exportToCSV()">Exportar</button>
  </div>-->
</div>

<div class="row" *ngIf="!isLoading else loadingTemplate">
  <ng-container *ngFor="let status of kanban.columns">
    <div class="col col-2 col--max-height">
      <div class="kanban-column" [ngStyle]="{'background-color': status.background_color}">
        <div class="kanban-column__header" [ngStyle]="{'color': status.font_color}">
          <span class="kanban-column__header__title">{{ status.name }} ({{ countFilteredColumn(status) }})</span>
          <span
            class="kanban-column__header__tag">{{ pendingValueInColumn(status)|currency:'BRL':'symbol':'1.2-2':'pt-br'  }}</span>
        </div>

        <div class="kanban-column__content">
          <ng-container *ngFor="let ticket of kanban.cards[status.order!]">
            <div class="kanban-card" *ngIf="showTicket(ticket)" (click)="openTicket(ticket)" [ngStyle]="{'background-color': getBackgroundColor(ticket)}">
              <span class="icon icon-warning task-icon task-icon--yellow" *ngIf="hasPendingTaskForToday(ticket)"></span>
              <span class="icon icon-check-round task-icon task-icon--green"
                    *ngIf="!hasLateTask(ticket) && !hasPendingTaskForToday(ticket) && isTaskCompletedForToday(ticket)"></span>
              <span class="icon icon-warning task-icon task-icon--red" *ngIf="hasLateTask(ticket)"></span>

              <div class="kanban-card__row d-flex justify-content-between">
                <span class="kanban-card__date" [ngStyle]="{'color': getFontColor(ticket)}">{{ ticket.date_time|date:'dd/MM/YYYY' }}</span>

                <div class="kanban-card__bullets">
                  <span *ngFor="let color of getBulletsForTicket(ticket, status.background_color)" class="bullet"
                        [style.background-color]="color"></span>
                </div>

                <span class="kanban-card__tag"
                      [ngStyle]="{'background-color': ticket.user__background_color, 'color': ticket.user__font_color}">
                  {{ getFirstWord(ticket.user__name) }}
                </span>
              </div>

              <div class="kanban-card__row">
                <strong [ngStyle]="{'color': getFontColor(ticket)}">{{ ticket.patient__name }}</strong>&nbsp;
                <small [ngStyle]="{'color': getFontColor(ticket)}">({{ ticket.patient__document_number || ''|mask:'000.000.000-00' }})</small>
              </div>

              <div class="kanban-card__row">
                <span class="kanban-card__tag text-uppercase" [ngStyle]="{
                  'background-color': ticket.health_insurance_plan?.health_insurance?.background_color,
                  'color': ticket.health_insurance_plan?.health_insurance?.font_color}">
                  {{ ticket.health_insurance_plan?.health_insurance?.name }}
                </span>
              </div>

              <div class="kanban-card__row">
                <span class="kanban-card__price kanban-card__price--red">
                  {{ ticket.pending_amount|currency:'BRL':'symbol':'1.2-2':'pt-br' }}
                </span>
                -
                <span class="kanban-card__price kanban-card__price--green">
                  {{ ticket.paid_amount|currency:'BRL':'symbol':'1.2-2':'pt-br' }}
                </span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
