<div class="attachment-modal-wrapper">
  <div class="row p-0 justify-content-end">
    <button class="btn btn-closed col-1" (click)="activeModal.close(false)">
      <i class="icon-close"> </i>
    </button>
  </div>
  <div class="attachment-modal" *ngIf="modalItems else loadingTemplate">
    <div class="col-12 m-0 p-0">
      <span class="h2">Anexos - {{title}}</span> <br />
      <p class="mt-2"><span class="font-weight-bold">Paciente:</span> {{patientName}}</p>
      <div *ngIf="attachmentsCurrentController.length > 0">
        <div class="row p-0 m-0  border-bottom">
          <div class="col-8 p-0 m-0 border-right d-flex align-items-center">
            <p class="font-weight-bold m-0 ml-2">Documento</p>
          </div>
          <div class="col-2 p-0 m-0 border-right d-flex flex-column align-items-center">
            <p class="font-weight-bold m-0 ml-2">Reembolso <br /> Garantido</p>
          </div>
          <div class="col-2 p-0 m-0 d-flex justify-content-center align-items-center">
            <p class="font-weight-bold m-0">Baixar</p>
          </div>
        </div>
        <div *ngFor="let attachment of attachmentsCurrentController; let index = index">
          <button class="nav_button p-0" (click)="downloadExam(attachment.file_url!)">
            <div class="row p-0 m-0 border-bottom">
              <div class="col-8 p-0 m-0 border-right text-left">
                <span class="notes">{{getFilename(attachment.filename)}} </span>
              </div>
              <div class="col-2 border-right d-flex justify-content-center"
                (click)="$event.stopImmediatePropagation(); isContractChanged('items', index)"
                *ngIf="type == 'Ticket'">
                <input type="checkbox" id="is_contract" [checked]="attachment.is_contract" *ngIf="isPdf(attachment.filename)"/>
              </div>
              <div class="col-2 p-0 m-0 d-flex justify-content-center align-items-center">
                <span class="i icon icon-download p-0"></span>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 m-0 p-0 mt-4" *ngIf="type == 'Procedimentos'">
      <div class="row p-0 m-0 border-bottom">
        <div class="col-12 p-0 m-0">
          <p class="font-weight-bold m-0 ml-2">Pedido médico</p>
        </div>
      </div>

      <ng-container *ngIf="!selectingMedicalOrder">
        <div class="row p-0 m-0 border-bottom" *ngIf="modalItems.medical_orders.length == 0">
          <div class="col-12 p-0 m-0">
            <p class="m-0">Nenhum arquivo selecionado</p>
          </div>
        </div>

        <button class="nav_button p-0" *ngFor="let medicalOrder of modalItems.medical_orders"
          (click)="downloadExam(medicalOrder.file)">
          <div class="row p-0 m-0 border-bottom">
            <div class="col-2 p-0 m-0 border-right text-left text-truncate">
              <span class="notes">{{ medicalOrder.created_at | date:'dd/MM/YYYY' }}</span>
            </div>

            <div class="col-3 p-0 m-0 border-right text-left text-truncate">
              <span class="notes">{{ medicalOrder.professional__name }} </span>
            </div>

            <div class="col-6 p-0 m-0 border-right text-left text-truncate">
              <span class="notes">{{ medicalOrder.filename }}</span>
            </div>

            <div class="col-1 p-0 m-0 d-flex justify-content-center align-items-center">
              <span class="i icon icon-download p-0"></span>
            </div>
          </div>
        </button>
      </ng-container>

      <ng-container *ngIf="selectingMedicalOrder">
        <ng-container *ngIf="selectingMedicalOrder && patientDocuments">
          <button class="nav_button p-0" *ngFor="let medicalOrder of patientDocuments"
            (click)="toggleMedicalOrder(medicalOrder)">
            <div class="row p-0 m-0 border-bottom">
              <div class="col-2 p-0 m-0 border-right text-left text-truncate">
                <span class="notes">{{ medicalOrder['created_at'] | date:'dd/MM/YYYY' }}</span>
              </div>

              <div class="col-3 p-0 m-0 border-right text-left text-truncate">
                <span class="notes">{{ medicalOrder['professional__name'] }} </span>
              </div>

              <div class="col-6 p-0 m-0 border-right text-left text-truncate">
                <span class="notes">{{ medicalOrder['filename'] }}</span>
              </div>

              <div class="col-1 p-0 m-0 d-flex justify-content-center align-items-center">
                <input type="checkbox" [checked]="medicalOrderIsSelected(medicalOrder)">
              </div>
            </div>
          </button>
        </ng-container>
      </ng-container>

      <button class="btn btn-outline-primary col-3" *ngIf="addNewAttachment"
        (click)="selectingMedicalOrder = !selectingMedicalOrder">
        <small *ngIf="selectingMedicalOrder">Salvar</small>
        <small *ngIf="!selectingMedicalOrder">Alterar pedido médico</small>
      </button>
    </div>

    <div class="col-12 m-0 p-0 mt-4" *ngIf="type == 'Procedimentos'">
      <div class="row p-0 m-0  border-bottom">
        <div class="col-11 p-0 m-0 border-right">
          <p class="font-weight-bold m-0 ml-2">Laudo</p>
        </div>

        <div class="col-1 p-0 m-0 d-flex justify-content-center">
          <p class="font-weight-bold m-0"></p>
        </div>
      </div>

      <ng-container *ngIf="!selectingMedicalReport">
        <div class="row p-0 m-0 border-bottom" *ngIf="modalItems.medical_reports.length == 0">
          <div class="col-12 p-0 m-0">
            <p class="m-0">Nenhum arquivo selecionado</p>
          </div>
        </div>

        <button class="nav_button p-0" *ngFor="let medicalReport of modalItems.medical_reports"
          (click)="downloadExam(medicalReport.file)">
          <div class="row p-0 m-0 border-bottom">
            <div class="col-11 p-0 m-0 border-right text-left text-truncate">
              <span class="notes">{{ medicalReport.filename }}</span>
            </div>

            <div class="col-1 p-0 m-0 d-flex justify-content-center align-items-center">
              <span class="i icon icon-download p-0"></span>
            </div>
          </div>
        </button>
      </ng-container>

      <ng-container *ngIf="selectingMedicalReport">
        <ng-container *ngIf="selectingMedicalReport && patientExams">
          <button class="nav_button p-0" *ngFor="let document of patientExams" (click)="toggleMedicalReport(document)">
            <div class="row p-0 m-0 border-bottom">
              <div class="col-11 p-0 m-0 text-left">
                <span class="notes">{{ document['filename'] }} </span>
              </div>

              <div class="col-1 p-0 m-0 d-flex justify-content-center align-items-center">
                <input type="checkbox" [checked]="medicalReportIsSelected(document)">
              </div>
            </div>
          </button>
        </ng-container>
      </ng-container>

      <button class="btn btn-outline-primary col-3" *ngIf="addNewAttachment"
        (click)="selectingMedicalReport = !selectingMedicalReport">
        <small *ngIf="selectingMedicalReport">Salvar</small>
        <small *ngIf="!selectingMedicalReport">Alterar laudos</small>
      </button>
    </div>

    <div class="col-12 m-0 p-0 mt-4" *ngIf="addNewAttachment">
      <div class="row p-0 m-0">
        <div *ngFor="let attachment of getAttachments(), let index = index" class="col-12 p-0 m-0 mb-2">
          <div class="d-flex align-items-center position-relative">
            <input class="attachment col-11" [value]="attachment.filename" (change)="setFilename(attachment, $event)">
            <label class="checkbox-contract" *ngIf="isPdf(attachment.filename)">
              Contrato
              <input type="checkbox" id="is_contract" (click)="isContractChanged('attachment', index)"
                [checked]="attachment.is_contract" />
            </label>
            <button class="btn btn-link col-1 p-0 m-0" (click)="deleteAttachment(attachment)">
              <i class="icon-close"></i>
            </button>
          </div>
        </div>
        <div class="mt-5">
          <span *ngIf="attachmentFeedback">Carregando - {{attachmentFeedback}}</span>
        </div>
      </div>

      <div class="row p-0 m-0">
        <label class="btn-add">
          <input type="file" (change)="addAttachment($event)" class="d-none" name="file[]" multiple="true"
            *ngIf="!attachmentFeedback"> Adicionar novo arquivo
        </label>
      </div>
      <div class="row mt-4 justify-content-end">
        <button class="btn btn-link btn-lg col-3 " (click)="activeModal.close(false)">Cancelar</button>
        <button class="btn btn-primary col-3" [disabled]="submitting" (click)="submitHandler()">
          Salvar
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
