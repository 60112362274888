import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-root-nav',
  templateUrl: './root-nav.component.html',
  styleUrls: ['./root-nav.component.scss']
})
export class RootNavComponent implements OnInit {

  showDropdownMenu: boolean = false;
  showRootMenuSidebar: boolean = false;

  constructor(private router: Router, private sessionManager: SessionManagerService) { }

  ngOnInit(): void {
  }

  linkListHeader = [
    {name: "Financeiro", routerLink: "financial", routerLinkActive: "active", icon: "icon-financial"}
  ];

  @HostListener('document:click', ['$event'])
  clickListener(event: Event) {
    if ((event.target! as HTMLElement).id != "dropdownButton") {
      this.showDropdownMenu = false;
    }
    if ((event.target! as HTMLElement).id == "sidebar-background") {
      this.showRootMenuSidebar = false;
    }
  }

  rootMenuButtonHandler() {
    this.showRootMenuSidebar = !this.showRootMenuSidebar;
  }

  avatarClickHandle() {
    this.showDropdownMenu = !this.showDropdownMenu;
  }

  logoutButtonHandler() {
    this.sessionManager.clearSessionData();
    this.router.navigate(["/login"]);
  }

  get profileImage() {
    let profileImage = this.sessionManager.getSessionData()?.profile_image;
    return profileImage;
  }

  get hasProfileImage(): boolean {
    return this.profileImage != null;
  }
}
