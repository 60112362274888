<div class="background"></div>
<div id="body-container">
  <div id="nav-container" class="container-fluid">
    <nav class="navbar navbar-expand-lg d-flex justify-content-between">
      <button class="navbar-toggler menu-button" (click)="rootMenuButtonHandler()">
        <i class="icon-menu"></i>
      </button>
      <div class="logo-wrapper">
        <img src="/assets/reembolsogarantido-logo.svg" alt="">

      </div>
      <div class="navbar-collapse" [ngClass]="showRootMenuSidebar ? 'collapseShow' : 'collapse' "
      (click)="showRootMenuSidebar ? rootMenuButtonHandler() : null" id="navbarHeader">
      <ul class="navbar-nav">
        <li class="nav-item" *ngFor="let l of linkListHeader">
          <!-- <a routerLink="{{l.routerLink}}" routerLinkActive="{{l.routerLinkActive}}">
            <i class="{{l.icon}}"></i>
            <span>{{l.name}}</span>
          </a> -->
        </li>
        <div class="line"></div>
        <li class="dropdown" *ngIf="!showRootMenuSidebar">
          <button id="dropdownButton" (click)="avatarClickHandle()" class="dropdown__avatar">
            <img class="profile-image" [src]="profileImage" *ngIf="hasProfileImage">
            <i class="profile-image profile-image--icon icon-user" *ngIf="!hasProfileImage"></i>
          </button>
        </li>
        <div *ngIf="showDropdownMenu || showRootMenuSidebar"
          [ngClass]="showRootMenuSidebar ? 'dropdown__menu-mobile' : 'dropdown__menu'">
          <button class="row" (click)="logoutButtonHandler()">
            <i class="icon-exit-to-app col-2 p-0"></i>
            <span class="col p-0">Sair</span>
          </button>
        </div>
      </ul>
    </div>
    <div *ngIf="showRootMenuSidebar" class="navbar-backdrop" (click)="rootMenuButtonHandler()">
    </div>
  </nav>
</div>
<div id="main-container" class="container-fluid">
  <router-outlet></router-outlet>
</div>

</div>
