import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ProtectedRouteGuard} from './core/guards/protected-route.guard';
import {PublicRouteGuard} from './core/guards/public-route.guard';
import {FinancialFormComponent} from './features/dashboard/financial/financial-form/financial-form.component';
import {FinancialTableComponent} from './features/dashboard/financial/financial-table/financial-table.component';
import {
  LoginHealthInsuranceComponent
} from './features/dashboard/login-health-insurance/login-health-insurance.component';
import {RootNavComponent} from './features/dashboard/navigation/root-nav/root-nav.component';
import {LoginComponent} from './features/public/login/login.component';
import {FinancialReportComponent} from './features/dashboard/financial/financial-report/financial-report.component';
import {KanbanComponent} from "./features/dashboard/financial/kanban/kanban.component";


const routes: Routes = [
  {path: "", pathMatch: "prefix", redirectTo: "login"},
  {path: "login", component: LoginComponent, canActivate: [PublicRouteGuard]},
  {
    path: "dashboard", component: RootNavComponent, canActivate: [ProtectedRouteGuard], children: [
      {path: '', pathMatch: 'full', redirectTo: '/dashboard/kanban'},
      {
        path: 'kanban', children: [
          {path: '', component: KanbanComponent},
          {path: ':id', component: KanbanComponent}
        ]
      },
      {
        path: "financial", children: [
          {path: "", component: FinancialTableComponent},
          {path: "register", component: FinancialFormComponent},
          {path: "edit/:id", component: FinancialFormComponent},
          {path: "report", component: FinancialReportComponent},
          {path: "report/edit/:id", component: FinancialFormComponent},
        ]
      },
    ]
  },
  {path: "loginPatient", component: LoginHealthInsuranceComponent, canActivate: [ProtectedRouteGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
