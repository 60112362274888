import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionManagerService } from '../services/session-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ProtectedRouteGuard implements CanActivate {

  constructor(public sessionManager: SessionManagerService, public router: Router) {}

  canActivate(): boolean {
    if(!this.sessionManager.loggedIn) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }

}
