<div id="login">
  <div class="container">
    <div class="row">
      <div class="col logo-bar">
        <img src="/assets/reembolsogarantido-logo.svg" alt="">
      </div>
    </div>

    <div class="row">
      <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="card">
          <img [src]="imageURL" *ngIf="imageURL" />
          <form [formGroup]="loginForm" (submit)="onSubmit($event)" class="form">
            <div class="mb-4">
              <label for="user" class="form__label">Usuário</label>
              <input type="text" formControlName="login" id="user" class="form-control form__input">
            </div>

            <div class="mb-5">
              <label for="password" class="form__label">Senha</label>
              <input type="password" formControlName="password" id="password" class="form-control form__input">
            </div>

            <div class="row">
              <div class="offset-md-3 col-12 col-md-6 mb-3">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="loading">
                  Sincronizar
                  <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
