import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardModule } from './dashboard/dashboard.module';
import { PublicModule } from './public/public.module';
import { TicketComponent } from '../shared/modals/ticket/ticket.component';
import {SharedModule} from "../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {CurrencyMaskModule} from "ng2-currency-mask";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";

@NgModule({
  declarations: [

    TicketComponent
  ],
    imports: [
        CommonModule,
        DashboardModule,
        PublicModule,
        SharedModule,
        ReactiveFormsModule,
        NgSelectModule,
        CurrencyMaskModule,
        NgbModule
    ]
})
export class FeaturesModule { }
