import { Injectable } from '@angular/core';
import { AccountModel } from '../models/account.model';
import { PermissionConst } from '../models/permission-const.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionManagerService {
  accountData: AccountModel = {} as AccountModel;
  patientData = {
    token: '',
    patient_id: '',
    ticket_id: ''
  };

  token: string = '';
  loggedIn: boolean = false;

  constructor() {
    this.getSessionData();
  }

  getSessionData() {
    const storedAccount = localStorage.getItem('account');

    if(storedAccount != undefined) {
      this.loggedIn = true;
      this.accountData = JSON.parse(storedAccount) as AccountModel;
      this.token = localStorage.getItem('token') as string;
    } else {
      this.loggedIn = false;
      this.accountData = {} as AccountModel;
    }

    return JSON.parse(storedAccount!) as AccountModel;
  }

  getSessionDataPatient() {
    const patient_id = localStorage.getItem('patient_id');
    const ticket_id = localStorage.getItem('ticket_id');
    this.token = localStorage.getItem('token') as string;

    if(this.token != '') {
      if(patient_id && ticket_id) {
        this.patientData = {
          patient_id: patient_id,
          ticket_id: ticket_id,
          token: this.token
        }
      }
      this.loggedIn = true;
    } else this.loggedIn = false;
  }

  setSessionDataPatient(data: any): void {
    this.patientData = data;
    this.token = this.patientData.token;
    localStorage.setItem('patient_id', this.patientData.patient_id);
    localStorage.setItem('ticket_id', this.patientData.ticket_id);
    localStorage.setItem('token', this.patientData.token);
    this.loggedIn = true;
  }

  setSessionData(data: any): void {
    this.loggedIn = true;
    if(data?.account) {
      this.accountData = JSON.parse(data.account);
    } else {
      this.accountData = data;
    }
    localStorage.setItem('account', JSON.stringify(this.accountData));
    if(this.accountData.token) {
      localStorage.setItem('token', this.accountData.token);
    }
    if(data?.token) {
      localStorage.setItem('token', data.token);
    }
  }

  clearSessionData(): void {
    this.loggedIn = false;
    this.accountData = {} as AccountModel;
    localStorage.removeItem('account');
    localStorage.removeItem('token');
  }

  getUserPermission(id: PermissionConst) {
    let hasPermission = false;
    let list = this.getSessionData().user_permissions;
    if(list) {
        hasPermission = list.some(permissionId => permissionId == id);
    }
    return hasPermission;
  }
}
