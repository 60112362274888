import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-protocol-modal',
  templateUrl: './protocol-modal.component.html',
  styleUrls: ['./protocol-modal.component.scss']
})
export class ProtocolModalComponent implements OnInit {

  modalItems!: any;
  protocols!: any;
  title: string = '';
  addNewProtocol: boolean = false;

  submitting: boolean = false;

  formGroup = this.fb.group({
    protocol: this.fb.array([])
  });

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toast: ToastrService) { }

  ngOnInit(): void {
    this.title = this.modalItems.name;
    this.initialize();
  }

  initialize() {
    if (this.modalItems.items) {
      this.protocols = this.modalItems.items;
      this.protocols.forEach((item: any, index: number) => {
        this.addProtocol();
        this.getProtocolForm(index).patchValue(item);
        this.getProtocolForm(index).get('date_time')?.setValue(moment(item.date_time).format('YYYY-MM-DD'));
      });
    } else {
      this.addProtocol();
    }
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      let formData = this.formGroup.getRawValue();
      formData.protocol.forEach((item: any) => {
        if (item.id == '') {
          item.id = undefined;
        }
        item.date_time = moment(item.date_time).format();
      })
      this.toast.success('Protocolo salvo com sucesso', 'Sucesso');
      this.activeModal.close(formData);
    } else {
      this.toast.error('Você não adicionou algum protocolo', 'Erro');
      this.submitting = false;
    }
  }

  get protocolForm() {
    return this.formGroup.get('protocol') as FormArray;
  }

  getProtocolForm(index: number) {
    return this.protocolForm.controls[index] as FormGroup;
  }

  addProtocol() {
    let protocolForm = this.fb.group({
      id: [''],
      date_time: ['', [Validators.required]],
      protocol: ['', [Validators.required]],
      is_active: [true]
    });
    this.protocolForm.push(protocolForm);
  }

  removeProtocol(at: number) {
    this.getProtocolForm(at).get("is_active")?.setValue(false);
  }

  getIsActive(index: number) {
    return this.getProtocolForm(index).get("is_active")?.value;
  }

  getDate(date: any) {
    let date_time = moment(date.date_time).format("DD/MM/YYYY");
    return date_time;
  }

}
