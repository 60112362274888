import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationModule } from './navigation/navigation.module';
import { FinancialModule } from './financial/financial.module';
import { LoginHealthInsuranceComponent } from './login-health-insurance/login-health-insurance.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    LoginHealthInsuranceComponent
  ],
  imports: [
    CommonModule,
    NavigationModule,
    FinancialModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DashboardModule { }
