import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicInputComponent } from './components/basic-input/basic-input.component';
import { SelectInputComponent } from './components/select-input/select-input.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ProtocolModalComponent } from './modals/protocol-modal/protocol-modal.component';
import { AttachmentModalComponent } from './modals/attachment-modal/attachment-modal.component';
import { MailingModalComponent } from './modals/mailing-modal/mailing-modal.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    BasicInputComponent,
    SelectInputComponent,
    FormErrorComponent,
    ProtocolModalComponent,
    AttachmentModalComponent,
    MailingModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxMaskModule,
    CurrencyMaskModule,
    EditorModule,
  ],
  exports: [
    BasicInputComponent,
    SelectInputComponent,
    FormErrorComponent,
  ]
})
export class SharedModule { }
