import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { last, map, tap } from 'rxjs';
import { FinancialService } from 'src/app/core/services/financial.service';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss']
})
export class AttachmentModalComponent implements OnInit {

  modalItems!: any;
  addNewAttachment: boolean = false;
  type!: any;
  title: string = '';
  patientName: string = '';
  patientId: number | null = null;

  loadingPatientExams = false;
  loadingPatientDocuments = false;

  patientExams = [];
  patientDocuments = [];

  submitting: boolean = false;

  attachmentsCurrentController: {
    file_url?: string,
    id?: number,
    temporary_file_id?: string,
    selected_attachment_id: string,
    filename: string,
    is_contract: boolean
  }[] = [];

  attachmentsController: {
    file_url?: string,
    id?: number,
    temporary_file_id: string,
    selected_attachment_id: string,
    filename: string,
    is_contract: boolean
  }[] = [];

  medicalOrder: any = null;
  medicalReport: any = null;
  attachmentFeedback = '';

  selectingMedicalReport = false;
  selectingMedicalOrder = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private financialService: FinancialService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.attachmentsCurrentController = [];
    this.attachmentsController = [];
    this.title = this.type;
    this.patientName = this.modalItems.name;
    this.patientId = this.modalItems.patientId;
    this.fetchAllPatientExams();
    this.fetchAllPatientDocuments();
    if (this.addNewAttachment && this.modalItems.attachments) {
      this.attachmentsController = this.modalItems.items.filter((item: any) => item.temporary_file_id);
      this.attachmentsCurrentController = this.modalItems.items.filter((item: any) => item.id);
    }
    if (!this.modalItems.attachments) {
      this.attachmentsCurrentController = this.modalItems.items || [];
    }
  }

  isContractChanged(target: string, index: number) {
    if (target == 'items') {
      let value = !this.attachmentsCurrentController[index].is_contract;
      if (value) {
        this.attachmentsController.forEach(item => item.is_contract = false);
        this.attachmentsCurrentController.forEach((item: any) => item.is_contract = false);
      }
      this.attachmentsCurrentController[index].is_contract = value;
    } else {
      let value = !this.attachmentsController[index].is_contract;
      if (value) {
        this.attachmentsController.forEach(item => item.is_contract = false);
        this.attachmentsCurrentController.forEach((item: any) => item.is_contract = false);
      }
      this.attachmentsController[index].is_contract = value;
    }
  }

  fetchAllPatientExams() {
    if (this.patientId) {
      this.loadingPatientExams = true;
      this.financialService.allExamsFromPatient(this.patientId).subscribe((response) => {
        this.loadingPatientExams = false;
        this.patientExams = response;
      });
    }
  }

  fetchAllPatientDocuments() {
    if (this.patientId) {
      this.loadingPatientDocuments = true;
      this.financialService.allDocumentsFromPatient(this.patientId).subscribe((response) => {
        this.loadingPatientDocuments = false;
        this.patientDocuments = response;
      });
    }
  }

  downloadExam(url: string) {
    window.open(url, '_blank');
  }

  getFilename(filename: String) {
    let name = '';
    if (filename) {
      name = filename.split('/').slice(-1).toString();
    }
    return name;
  }

  isPdf(filename: String) {
    let name = filename.split('/').slice(-1).toString();
    return name.split('.').slice(-1).toString() == 'pdf';
  }

  getAttachments() {
    return this.attachmentsController.filter(attachment => attachment);
  }

  addAttachment(event: any) {
    let files: FileList = event.target.files;
    let filenames: string[] = [];
    let formData: FormData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
      filenames.push(file.name);
    });
    this.financialService.uploadTemporaryFile(formData).pipe(
      map((event: any, formData: any) => {
        switch (event['type']) {
          case HttpEventType.UploadProgress:
            let percentage = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            return `Envio de arquivo em ${percentage}%.`;
          case HttpEventType.Response:
            event.body.forEach((attachment: any, index: number) => {
              let attachmentForm = {
                id: this.modalItems.id,
                model_name: 'TEMPORARY_FILE',
                temporary_file_id: attachment['id'],
                selected_attachment_id: attachment['id'],
                filename: filenames[index],
                is_contract: attachment['is_contract']
              };
              this.attachmentsController.push(attachmentForm);
            });
            return '';
          default:
            return null;
        }
      }),
      tap(message => {
        this.attachmentFeedback = message || '';
      }),
      last()
    ).subscribe(response => {
      /**/
    }, error => {
      console.error(error);
    });
  }

  setFilename(attachment: any, $event: any) {
    this.attachmentsController = this.attachmentsController.filter(controller => controller != attachment);
    attachment.filename = $event.target.value;
    this.attachmentsController.push(attachment);
  }

  deleteAttachment(attachment: any) {
    this.attachmentsController = this.attachmentsController.filter(item => item != attachment);
  }

  submitHandler() {
    this.submitting = true;

    this.attachmentsController.forEach(item => {
      console.log(item);
      console.log(this.attachmentsCurrentController);
      this.attachmentsCurrentController.push(item);
    });

    this.attachmentsCurrentController.map(item => {
      if (item.temporary_file_id == '' || item.temporary_file_id == undefined) {
        item.temporary_file_id = undefined;
      }
      if (item.id == 0) {
        item.id = undefined;
      }
    });

    let medicalOrderIds = this.modalItems.medical_orders.map((item: any) => item.id);
    let medicalReportIds = this.modalItems.medical_reports.map((item: any) => item.id);
    let response = { attachmentsController: this.attachmentsCurrentController, medicalOrderIds, medicalReportIds };
    this.activeModal.close(response);
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], 'Erro');
    }
  }

  medicalOrderIsSelected(medicalOrder: any) {
    return this.modalItems.medical_orders.some((item: any) => medicalOrder.id == item.id);
  }

  toggleMedicalOrder(medicalOrder: any) {
    let hasMedicalOrder = this.medicalOrderIsSelected(medicalOrder);
    if (hasMedicalOrder) {
      this.modalItems.medical_orders = this.modalItems.medical_orders.filter((item: any) => medicalOrder.id != item.id);
    } else {
      this.modalItems.medical_orders.push(medicalOrder);
    }
  }

  medicalReportIsSelected(medicalReport: any) {
    return this.modalItems.medical_reports.some((item: any) => medicalReport.id == item.id);
  }

  toggleMedicalReport(medicalReport: any) {
    let hasMedicalReport = this.medicalReportIsSelected(medicalReport);
    if (hasMedicalReport) {
      this.modalItems.medical_reports = this.modalItems.medical_reports.filter((item: any) => medicalReport.id != item.id);
    } else {
      this.modalItems.medical_reports.push(medicalReport);
    }
  }

}
